/* ---------------------
     BECU Brand
------------------------- */
/*

Action Blue - #4470D6
BECU Red - #D12A2E
Harbor - #007C89
Dark Teal - #005460
Ocean - #006979
Obsidian (Text dark) - #192838
Stone (Text light) - #4d5f69
Snow - #fff
Cloud - #F4F5F5
Rain - #EAEBEC
Storm - #D5D7D9
Amber - #FFA300

*/

button:focus-visible,
a:focus-visible {
  outline: 2px solid #4470D6 !important;
  box-shadow: none !important;
}

.text-link-button:focus-visible {
  text-decoration: underline 4px;
}

/* 406213: Fix for Modal "X" outline/border */
.close:focus {
  -moz-outline-radius-topright: 0.25rem;
}

input:focus,
.custom-select:focus {
  box-shadow: 0 0 8px rgb(68 112 214 / 60%) !important;
  border: 2px solid #4470D6 !important;
  outline: 0;
}

@media (max-width: 576px) {
  .form-inline .form-group {
    flex-flow: row wrap;
  }
}

.form-control.is-invalid {
  border-color: #dc3545 !important;
}

.form-control.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%) !important;
}

.row.dark a:focus,
.body-container.dark a:focus {
  outline: 2px dashed #ffffff;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

@media screen and (max-width: 767px) {
  input,
  select,
  textarea {
    font-size: 1.15rem !important;
  }
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

/* Application */
html,
body {
  height: 100%;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  color: #4d5f69 !important;
  font-family: 'Public Sans', sans-serif !important;
  font-size: 16px !important;
  position: relative;
  /* padding-bottom: 80px; */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 576px) {
  html {
    font-size: 14px;
  }
}

/*Scroll bar display Chrome and Edge*/
/*body::-webkit-scrollbar {
  display: none;
}*/

#content {
  background-color: #f4f5f5;
}

/***************************
  TYPOGRAPHY
****************************/
.hero,
h1,
h2,
h3,
h4 {
  color: #192838 !important;
  font-family: 'Public Sans', sans-serif;
}

.hero {
  font-size: 2.5rem !important;
  font-weight: 600 !important;
}

h1 {
  font-size: 2.25rem !important;
  font-weight: 600 !important;
}

h2 {
  font-size: 2rem !important;
  font-weight: 600 !important;
}

h3 {
  font-size: 1.75rem !important;
  font-weight: 600 !important;
}

h4 {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
}

.recommendation-title {
  font-size: 20px !important;
}

@media screen and (max-width: 767px) {
  .hero {
    font-size: 36px !important;
  }

  h1 {
    font-size: 32px !important;
  }

  h2 {
    font-size: 28px !important;
  }

  h3 {
    font-size: 24px !important;
  }

  h4 {
    font-size: 20px !important;
  }
}

#HomeButton {
  font-size: 24px !important;
}

i {
  margin-right: 8px;
}

.card-header button {
  font-family: 'IBM Plex Sans', sans-serif;
}

p.bold,
span.bold {
  font-weight: 600;
  color: #192838;
}

p.light,
span.light {
  font-weight: 400;
  color: #4d5f69;
}

p.subtitle {
  font-size: 0.786rem;
  font-weight: 600;
  color: #192838;
}

.not-found {
  text-align: center;
}

.not-found h2 {
  font-size: 1.618rem;
  margin: 24px 0;
}

.not-found p {
  margin-bottom: 48px;
}

.not-found .btn {
  margin: 4px;
}

.not-found i {
  color: #ab111a;
  font-size: 1.618rem;
}

.not-found img {
  min-width: 40px;
  max-width: 200px;
}

@media (min-width: 576px) {
  .not-found img {
    max-width: 250px;
  }
}

/***************************
  .btn Buttons
****************************/
.btn-link {
  color: #007c89;
}

.btn {
  width: 90%;
  min-height: 50px;
  font-size: 16px !important;
  font-weight: 600 !important;
  border-width: 2px !important;
  border-radius: 10px !important;
}

.btn-primary[aria-disabled='true'] {
  color: #4d5f69 !important;
  background-color: #cccccc !important;
  border-color: #cccccc !important;
  cursor: not-allowed;
}

.btn-secondary[aria-disabled='true'] {
  color: #cccccc !important;
  background-color: #ffffff !important;
  border-color: #cccccc !important;
  cursor: not-allowed;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
  outline: 2px solid #4470D6;
  outline-offset: 2px;
}

.btn-primary {
  background-color: #007c89 !important;
  border-color: #007c89 !important;
}

.btn-primary:focus {
  background-color: #007c89 !important;
}

.btn-secondary {
  color: #192838 !important;
  background-color: #ffffff !important;
  border-color: #4d5f69 !important;
}

@media(hover: hover) {
  .btn-primary:hover {
    background-color: #005460 !important;
    border-color: #005460 !important;
  }

  .btn-secondary:hover {
    background-color: #F4F5F5 !important;
  }

  .btn-feedback:hover {
    color: #192838 !important;
    background-color: #ffffff !important;
  }

  .link-button:hover {
    color: #007c89 !important;
    text-decoration-line: underline !important;
    text-underline-position: under !important;
  }

  .text-link-button:hover {
    text-decoration: underline 4px !important;
  }

  .close:hover {
    opacity: 1;
  }
}

.btn-primary:active {
  background-color: #000000 !important;
  border-color: #000000 !important;
}

.btn-secondary:active {
  background-color: #e3e1db !important;
}

.btn-hide-pointer {
  pointer-events: none;
}

.btn-feedback {
  color: #ffffff !important;
  background-color: #192838 !important;
  border: 2px solid #192838 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.btn-feedback:focus {
  color: #192838 !important;
  background-color: #ffffff !important;
}

.link-button {
  background: none !important;
  border: none !important;
  padding: 4px !important;
  text-decoration-line: underline;
  text-underline-position: under;
  font-weight: 600;
  color: #007c89 !important;
}

.link-button:focus-visible {
  color: #007c89 !important;
  text-decoration-line: underline !important;
  text-underline-position: under !important;
}

.popover-button {
  color: #192838 !important;
  background-color: #D9F7EA !important;
  border: 2px solid #D9F7EA !important;
  padding: 15px !important;
  font-weight: 700;
  border-radius: 8px;
  white-space: nowrap;
  outline-offset: 2px;
  font-size: 14px;
}

.add-goals-confirm:disabled {
  color: #CCCCCC !important;
  cursor: not-allowed;
}

.text-link-button {
  background: none !important;
  border: none !important;
  padding: 5px !important;
  font-weight: 600;
  color: #007c89;
  text-decoration-line: underline !important;
  text-underline-position: under !important;
}

.close {
  opacity: 0.5;
}

.close:focus {
  opacity: 1;
}

.btn-close {
  opacity: 1 !important;
  color: #4d5f69 !important;
  border-radius: 0 !important;
  background: transparent url('./assets/images/icons/close.svg') !important;
  height: 40px !important;
  width: 40px !important;
  box-sizing: border-box !important;
}

@media (max-width: 576px) {
  .btn-feedback {
    bottom: 64px;
  }

  .nav-link {
    font-size: 14px !important;
  }
}

@media (min-width: 577px) {
  .btn-feedback {
    bottom: 50vh;
  }
}

/* Alerts */
.alert .btn-close {
  color: #192838 !important;
  width: 32px !important;
  height: 32px !important;
  background: transparent url('./assets/images/icons/alert-close.svg') !important;
  margin: 4px;
}

.alert-dismissible .btn-close {
  padding: 0 !important;
}

.alert-danger .close {
  color: #721c24 !important;
  opacity: 0.5 !important;
}

.alert-danger .link-button {
  color: #491217 !important;
  font-weight: 600;
}

/* Links */
a {
  font-weight: 600;
  color: #007c89 !important;
  cursor: pointer;
}

a:hover,
a:focus {
  text-decoration: underline;
}

/*Header Navigation*/
.nav-link {
  height: 46px !important;
  padding: 10px 15px !important;
  font-weight: 600 !important;
  color: #192838 !important;
  line-height: 160% !important;
}

.nav-link,
.nav-link:hover,
.nav-link:focus-visible {
  text-decoration: underline !important;
  text-underline-position: under;
}

.nav-link:focus-visible {
  outline-offset: 0px !important;
}

.navbar-nav .nav-link i {
  color: #007c89;
  font-weight: 600;
}

.navbar-brand a,
.navbar-brand a:hover {
  color: #192838 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: '';
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.no-underline {
  text-decoration-line: none !important;
}

/* Accordion */
.accordion-header:hover {
  background-color: #ffffff !important;
}

/* Dropdown */
.dropdown-menu.show {
  width: auto;
  min-width: 100%;
}

.dropdown-item {
  font-weight: 600 !important;
  text-decoration-line: underline !important;
  text-underline-position: under;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #007c89 !important;
  background-color: #e9ecef !important;
}

.article-dropdown-item {
  white-space: normal !important;
}

/* Survey Monkey */
.smcx-embed,
.smcx-embed > .smcx-iframe-container {
  height: 50vh !important;
  max-width: 100% !important;
}

/* Animations */
@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0) rotate(-90deg);
  }

  to {
    transform: translate3d(0, 0, 0) rotate(-90deg);
    opacity: 1;
    display: flex;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0) rotate(-90deg);
  }

  to {
    transform: translate3d(0, 0, 0) rotate(-90deg);
    opacity: 1;
  }
}

.animatedFadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  animation-delay: 3s;
  -webkit-animation-delay: 3s;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}

/* ListGroup */
.list-group-item {
  font-weight: 600;
}

.list-group-item.active {
  color: white !important;
  background-color: #007C89 !important;
  border-color: #007C89 !important;
}

.list-group-flush>.list-group-item {
  border-bottom-width: 1px !important;
}

.goal-confirmation-tile.active {
  color: #212529 !important;
  background-color: white !important;
  border-color: rgba(0, 0, 0, 0.125) !important;
}

.no-padding {
  padding: 0 !important;
}

.auto-height {
  height: auto !important;
}

/* Footer */
@media screen and (max-width: 768px) {
  .footer-copyright {
    text-align: center;
  }
}

@media screen and (min-width: 768px) {
  .footer-terms-conditions {
    margin-left: auto;
  }
}

/* Radio */
.radio-button input {
  margin-top: 0.3rem !important;
}

img.full-width {
  width: 100%;
}

/* Card */
.card {
  border: 1px solid #f4f5f5;
  box-shadow: 0px 2px 2px #bcbfc2;
}
.card-body {
  padding: 32px 24px 24px 24px;
}

.add .card-body {
  padding: 24px 24px 16px 24px;
}

.card-footer {
  border-top: none;
}

.link-dropdown:after {
  display: none !important;
}

.dropdown-menu {
  width: 100%;
}

/* Checkbox */
.form-check-input {
  margin-top: 0.2rem !important;
}

.form-check-input, .form-check-label {
  cursor: pointer;
}

/* Date Input */
.date-input-group {
  flex-wrap: nowrap !important;
}

.react-datepicker__input-container input {
  border: 1px solid #4d5f69;
  border-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 38px;
  width: 100%;
  color: #495057;
  padding: .375rem 1.75rem .375rem .75rem;
}

.react-datepicker__input-container input:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.react-datepicker__navigation-icon {
  top: 5px !important;
}

/* Input Group */
.input-group-text {
  white-space: normal !important;
}

.input-height-44 {
  height: 44px !important;
  border-radius: 0.25rem 0px 0px 0.25rem !important;
}

/* Step Tracker + Media Queries (Alert) */
.inactive-step {
  color: #4d5f69 !important;
}

@media (max-width: 575px) {
  .step-tracker-line {
    width: 68% !important;
    left: 16% !important;
  }
}

@media (min-width: 576px) {
  .step-tracker-line {
    width: 70% !important;
    left: 15% !important;
  }
}

@media (min-width: 768px) {
  .step-tracker-line {
    width: 74% !important;
    left: 13% !important;
  }
}

@media (min-width: 992px) {
  .step-tracker-line {
    width: 78% !important;
    left: 11% !important;
  }

  .recommendation-status-alerts {
    width: 370px !important;
  }
}

@media (min-width: 1200px) {
  .step-tracker-line {
    width: 82% !important;
    left: 9% !important;
  }

  .recommendation-status-alerts {
    width: 440px !important;
  }
}

@media (min-width: 1400px) {
  .step-tracker-line {
    width: 86% !important;
    left: 7% !important;
  }

  .recommendation-status-alerts {
    width: 520px !important;
  }
}

/* Spinner */
.spinner, .spinner:after {
  border-radius: 50%;
  width: 6em;
  height: 6em;
}

.spinner {
  margin: 40px auto;
  position: relative;
  border-top: 0.6em solid rgba(0, 0, 0, 0.1);
  border-right: 0.6em solid rgba(0, 0, 0, 0.1);
  border-bottom: 0.6em solid rgba(0, 0, 0, 0.1);
  border-left: 0.6em solid #192838;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: loading 1.1s infinite linear;
  animation: loading 1.1s infinite linear;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Breadcrumb */
.breadcrumb-item {
  padding-right: 0.5rem;
  text-decoration-line: underline;
  text-underline-position: under;
  text-decoration-color: #007C89;
}

.breadcrumb-item.active {
  color: #192838 !important;
  font-weight: 800;
  text-decoration-line: none;
}

.breadcrumb-item a {
  text-decoration: none;
}

.breadcrumb-item a:hover, .breadcrumb-item a:focus {
  text-decoration: underline;
  text-underline-position: under;
  outline-offset: 2px;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 0 !important;
}

.breadcrumb-item+.breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, url('./assets/images/icons/breadcrumb-chevron.svg')) !important;
  padding-top: 2px;
}

/* dFHC */
.help-card {
  background-color: #F4F5F5 !important;
  box-shadow: none !important;
}

.financial-health-text {
  padding-left: 4px !important;
}

@media (max-width: 767px) {
  .financial-health-text {
    padding-top: 20px !important;
    padding-left: 15px !important;
  }
}

/* Skip Link */
.skip-link {
  position: absolute;
  transform: translateY(-200%);
  transition: transform 0.3s ease-out;
  color: white !important;
  background-color: #192838;
  border-bottom: solid 1px white;
  padding: 10px 15px;
  margin: 15px 10px;
  z-index: 2;
}

.skip-link:focus {
  transform: translateY(0);
}

/* Misc Styles */
.tooltip-popover:focus {
  outline: none !important;
}

.recommendation-tile::marker {
  color: #192838;
  font-size: 24px;
  font-weight: 600;
}

@media (max-width: 767px) {
  .recommendation-tile::marker {
    font-size: 20px;
  }
}

.profile-question-header:focus {
  outline: none !important;
}

/* Selection Tile */
.selection-tile:hover {
  background-color: #F4F5F5;
}

.selection-tile:focus {
  outline: 2px solid #4470D6 !important;
  outline-offset: 2px;
  -moz-outline-radius: 0;
}

.selection-tile-no-hover {
  background-color: inherit !important;
}

/* Modal */
.modal-header {
  padding: 24px !important;
  padding-bottom: 8px !important;
}

.modal-body {
  padding: 24px !important;
  padding-top: 8px !important;
}

.modal-body-footer-present {
  padding-bottom: 8px !important;
}

/* Switch */
.custom-switch .form-check-input {
  border-color: #005460 !important;
  width: 28px !important;
}

.custom-switch .form-check-input:checked {
  background-color: #005460;
}

.custom-switch .form-check-input:not(:checked):focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e") !important;
}

.custom-switch .form-check-input:not(:checked):focus-visible {
  outline: 2px solid #4470D6 !important;
  box-shadow: none !important;
}

.custom-switch .form-check-label {
  background: none !important;
  border: none !important;
  font-weight: 600;
  color: #005460 !important;
  text-decoration-line: underline !important;
  text-underline-position: under !important;
}

@media(hover: hover) {
  .custom-switch .form-check-label:hover {
    text-decoration: underline 4px !important;
  }
}